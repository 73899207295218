

a, .link {
    outline: none;
    color: inherit;
    text-decoration: underline;
    background-color: transparent;
    transition: color $ts, border-color $ts;

    &:hover {
        text-decoration: underline;
    }

    &:active {
        text-decoration: underline;
    }
}

sub, sup {
    font-size: 65%;
}

img {
    max-width: 100%;

    &.fullwidth {
        display: block;
        width: 100%;
        height: auto;
    }

    &.b-lazy {

        p & {
            opacity: 0;
            transition: opacity $ts;
        }
    }

    &.b-loaded {

        p & {
            opacity: 1;
        }
    }
}

strong {
    font-weight: 700;
}

h1, .h1 {
    position: relative;
    font-family: $font2;
    font-size: 68rem/$k;
    font-weight: 400;
    line-height: 0.97;
    word-spacing: 0.2em;
    text-transform: uppercase;
    margin: 180rem/$k 0 0;

    @media (max-width: $tablet) {
        font-size: 50rem/$k;
    }

    @media (max-width: $mobile) {
        font-size: 32rem/$k;
        text-transform: none;
    }

    &:first-child {
        margin-top: 0;
    }


    &.pd400 {
        padding-right: 400px;
        @media (max-width: $tablet) {
            padding-right: 0px;
        }
    }

    &.pd500 {
        padding-right: 500px;
        @media (max-width: $tablet) {
            padding-right: 0px;
        }
    }


    &.pd100 {
        padding-right: 100px;
        @media (max-width: $tablet) {
            padding-right: 0px;
        }
    }
}

h2, .h2 {
    font-family: $font2;
    font-size: 48rem/$k;
    font-weight: 400;
    line-height: (58/48);
    word-spacing: 0.2em;
    margin: 130rem/$k 0 0;

    @media (max-width: $tablet) {
        font-size: 36rem/$k;
        margin-top: 90rem/$k;
    }

    @media (max-width: $mobile) {
        font-size: 28rem/$k;
        margin-top: 50rem/$k;
    }

    &:first-child {
        margin-top: 0;
    }

    & + p, & + .p {
        margin-top: 55rem/$k;

        @media (max-width: $mobile) {
            margin-top: 30rem/$k;
        }
    }
}

h3, .h3 {
    font-family: $font2;
    font-size: 22.4rem/$k;
    font-weight: 400;
    line-height: (56/48);
    margin: 40rem/$k 0 0;

    @media (max-width: $tablet) {
        font-size: 30rem/$k;
    }

    @media (max-width: $mobile) {
        font-size: 24rem/$k;
    }

    &:first-child {
        margin-top: 0;
    }
}

h4, .h4 {
    font-family: $font2;
    font-size: 30rem/$k;
    font-weight: 400;
    line-height: (24/24);
    margin: 55rem/$k 0 0;

    @media (max-width: $tablet) {
        font-size: 24rem/$k;
    }

    @media (max-width: $mobile) {
        font-size: 20rem/$k;
    }

    &:first-child {
        margin-top: 0;
    }
}

h5, .h5 {
    font-family: $font1;
    font-size: 30rem/$k;
    font-weight: 200;
    line-height: (45/30);
    margin: 30rem/$k 0 0;

    &:first-child {
        margin-top: 0;
    }
}

p, .p {
    margin: 20rem/$k 0 0;
    -webkit-text-size-adjust: 100%;

    &:first-child {
        margin-top: 0;
    }
}

ol, ul {
    margin: 20rem/$k 0 0;
    padding: 0;

    &:first-child {
        margin-top: 0;
    }

    li {
        margin: 0 0 0;
        padding: 0 0 0 30rem/$k;

        &:first-child {
            margin-top: 0;
        }
    }
}

li {

    ul, ol {
        margin-top: 7rem/$k;
    }

    li {
        margin-top: 7rem/$k;
    }
}

ul {
    list-style: none;

    li {
        position: relative;
        padding-left: 30rem/$k;
        text-indent: -30rem/$k;
        @include column-break-inside();

        * {
            text-indent: 0;
        }

        &:before {
            position: relative;
            display: inline-block;
            width: 2em;
            text-align: left;
            letter-spacing: 0;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            text-indent: 0;
            content: "―";
        }
    }
}

ol {
    counter-reset: ol_counter;

    li {
        text-indent: -30rem/$k;
        list-style: none;
        padding: 0 0 0 45rem/$k;

        &:before {
            display: inline-block;
            text-indent: 0;
            @include size(20rem/$k);
            line-height: 22rem/$k;
            margin-right: 10rem/$k;
            text-align: center;
            background-color: #c2c2c2;
            border-radius: 50%;
            color: #fff;
            counter-increment: ol_counter;
            content: counter(ol_counter);
        }
    }
}

nav, .nav {

    ul {
        margin: 0;
        padding: 0;
    }

    li {
        margin: 0;
        padding: 0;
        text-indent: 0;

        &:before {
            display: none;
        }
    }
}

table {

    th, td {
        padding: 3rem/$k 10rem/$k;
    }
}

.hr, hr {
    margin: 70rem/$k 0 50rem/$k;
    width: 100%;
    padding: 0;
    border: none;
    border-top: 1px solid $color2;

    @media (max-width: $tablet) {
        margin: 40rem/$k 0 30rem/$k;
    }

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &--nomargin {
        margin: 0;
    }

    &--bolder {
        border-top-width: 3rem/$k;
    }

    h1 + &,
    .h1 + & {
        margin-top: 35rem/$k;

        @media (max-width: $tablet) {
            margin-top: 20rem/$k;
        }
    }
}
