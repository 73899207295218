
/*
Лейаут футера
*/

.footer {
    position: relative;
    margin-top: auto;
    padding-top: 140rem/$k;

    @media (max-width: $screen_xxlarge) {
        padding-top: 50rem/$k;
    }

    @media (max-width: $tablet) {
        padding-top: 0;
    }

    &__inner {
        padding: 25rem/$k 0;
        border-top: 1px solid #585154;
        //border-bottom: 1px solid #585154;

        @media (max-width: $tablet) {
            padding: 20rem/$k 0;
        }

        @media (max-width: $mobile) {
            padding: 35rem/$k 0;
        }
    }
}


.cols-wrapper--footer {
    margin: 0 -20rem/$k;

    > .cols {
        table-layout: auto;

        @media (max-width: $mobile) {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        > .col {
            position: relative;
            vertical-align: middle;
            padding-left: 20rem/$k;
            padding-right: 20rem/$k;

            @media (max-width: $mobile) {
                flex: auto 1 1;
            }
        }
    }

    .col--logo {

    }

    .col--social {
        flex: auto 0 0;
    }
}



.fmenu {
    position: relative;
    margin-left: -70rem/$k;
    @include margin-top(10rem/$k);

    @media (max-width: $tablet) {
        margin-left: -30rem/$k;
    }

    @media (max-width: $mobile) {
        margin-left: 0;
    }

    &__list {
        display: inline-block;
        vertical-align: top;
        min-width: 100%;
        font-size: 0;
        margin-bottom: -15rem/$k;

        @media (max-width: $mobile) {
            margin-bottom: -10rem/$k;
        }
    }

    &__item {
        position: relative;
        display: inline-block;
        vertical-align: top;
        font-size: 16rem/$k;
        line-height: (22/18);
        margin-left: 70rem/$k;
        margin-bottom: 15rem/$k;

        @media (max-width: $tablet) {
            margin-left: 30rem/$k;
            font-size: 16rem/$k;
        }

        @media (max-width: $mobile) {
            display: block;
            margin-left: 0;
            margin-bottom: 10rem/$k;
        }
    }

    &__link {
        position: relative;
        display: inline-block;
        vertical-align: top;
        text-decoration: none!important;
        transition: all $ts;
    }
}