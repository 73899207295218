/*

Sliders

 */


.swiper-slider {
    position: relative;
    @include margin-top(65rem/$k);
}

.swiper-lazy-preloader {
    top: 50%!important;
}


.swiper-button-prev,
.swiper-button-next {
    position: absolute;
    transform: translateY(-50%);
    background: none;
    margin-top: 0;
    margin-left: 0!important;
    @include size(65rem/$k);
    border-radius: 100rem/$k;
    font-size: 20rem/$k;
    background: transparent;
    border: 1px solid $color3;
    color: $color3;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    outline: none;
    user-select: none;
    transition: all $ts;
    line-height: 1;

    .icon {
        vertical-align: top;
    }

    &:before {
        @include position(absolute, -20rem/$k -20rem/$k -20rem/$k -20rem/$k);
        content: '';
    }

    &:after {
        content: '';
    }

    &:hover {
        opacity: 1;
        background-color: rgba(#fff, 0.3);
    }

    &.swiper-button-disabled {
        opacity: 0.5;
    }
}

.swiper-button-disabled {
    pointer-events: none;
}


.swiper-pagination-wrapper {
    @include margin-top(130rem/$k);

    @media (max-width: $tablet) {
        margin-top: 75rem/$k;
    }

    @media (max-width: $mobile) {
        margin-top: 50rem/$k;
    }
}

.swiper-pagination-container {
    position: relative;

    &:before {
        @include position(absolute, 7rem/$k 0 null 0);
        border-bottom: 1px solid $color2;
        content: '';

        @media (max-width: $tablet) {
            top: 5rem/$k;
        }
    }
}

.swiper-pagination {
    position: absolute;
    z-index: 1;
    pointer-events: none;
    text-align: center;
    font-size: 0;
    line-height: 1;
    min-height: 15rem/$k;

    @media (max-width: $tablet) {
        min-height: 11rem/$k;
    }

    &.swiper-pagination-bullets {
        bottom: 35rem/$k;
        left: 0;
        right: 0;
        width: auto;
        margin-left: -5rem/$k;
        margin-right: -5rem/$k;
        margin-bottom: -10rem/$k;
        pointer-events: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .swiper-pagination-bullet {
            margin: 0 5rem/$k 10rem/$k;
            pointer-events: auto;

            &-active {
                pointer-events: none;
            }
        }
    }

    &--static {
        @include margin-top(70rem/$k);
        position: relative;

        &.swiper-pagination-bullets {
            bottom: auto;
        }
    }

    &-bullet {
        position: relative;
        display: inline-block;
        vertical-align: top;
        pointer-events: auto;
        @include size(15rem/$k);
        border-radius: 100rem/$k;
        background-color: $color2;
        border: 1px solid transparent;
        opacity: 1;
        outline: none;
        transition: background-color $ts, opacity $ts;

        @media (max-width: $tablet) {
            @include size(11rem/$k);
        }

        &:before {
            @include position(absolute, -5rem/$k -5rem/$k -5rem/$k -5rem/$k);
            border-radius: 100rem/$k;
            content: '';
        }

        &:hover {
            background-color: $color3;
        }

        &-active {
            opacity: 1;
            background-color: $color3;

            &:hover {
                background-color: $color3;
            }
        }
    }
}

.swiper-slider--start.swiper-slider--end .swiper-pagination-bullets {
    display: none;
}

.swiper-pagination-bullet-active {

    &:first-child:last-child {
        display: none;
    }
}

.swiper-pagination-bullet-timer {
    display: none;
}


.swiper-slide {
    height: auto;

    img {

        &.swiper-lazy {
            opacity: 0;
            transition: all $ts;
        }

        &.swiper-lazy-loaded {
            opacity: 1;
        }
    }
}