

img.b-lazy {
    min-width: 1px;
    min-height: 1px;
    opacity: 0;
    transition: opacity 0.5s;
}

img.b-loaded {
    opacity: 1;
}


.img-to-bg-lazy {
    opacity: 0;
    transition: opacity 0.5s;

    &-loaded {
        opacity: 1;
    }
}


.image {
    display: block;
    position: relative;
    padding-bottom: 100%;
    margin-top: 135rem/$k;
    margin-bottom: 135rem/$k;
    background-color: rgba($color2, 0.4);

    @media (max-width: $tablet) {
        margin-top: 75rem/$k;
        margin-bottom: 75rem/$k;
    }

    @media (max-width: $mobile) {
        margin-top: 50rem/$k;
        margin-bottom: 50rem/$k;
    }

    &--ml {
        margin-top: 120rem/$k;
        margin-bottom: 120rem/$k;

        @media (max-width: $tablet) {
            margin-top: 65rem/$k;
            margin-bottom: 65rem/$k;
        }

        @media (max-width: $mobile) {
            margin-top: 50rem/$k;
            margin-bottom: 50rem/$k;
        }
    }

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &--transparent {
        background-color: transparent;
    }

    &--rounded {
        border-radius: 50%;

        .image__inner {
            border-radius: 50%;
        }
    }

    &__inner {
        @include position(absolute, 0 0 0 0);
        background: center center no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;

        &--contain {
            background-size: contain;
        }
    }

    &__bottom {
        @include position(absolute, null 0 0 0);
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        z-index: -100;
    }
}

/*
Content Section
*/

.section {
    position: relative;
    z-index: 1;
    padding: 125rem/$k 0;

    @media (max-width: $screen_xxlarge) {
        padding-top: 100rem/$k;
        padding-bottom: 100rem/$k;
    }

    @media (max-width: $tablet) {
        padding-top: 75rem/$k;
        padding-bottom: 75rem/$k;
    }

    @media (max-width: $mobile) {
        padding-top: 50rem/$k;
        padding-bottom: 50rem/$k;
    }

    &--bring-to-front {
        z-index: 2;
    }

    &--bring-to-back {
        z-index: 0;
    }

    &--flex {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &--flex-start {
        justify-content: flex-start;
    }

    &--flex-end {
        justify-content: flex-end;
    }

    &--fullheight {
        flex: auto 1 1;
        min-height: 100%;
    }

    &--nopadding {
        padding: 0;
    }

    &__bg {
        @include position(absolute, 0 0 0 0);
        background: center center no-repeat;
        background-size: cover;
        z-index: -1;
        overflow: hidden;

        &--z-index-auto {
            z-index: auto;
        }

        &--contain {
            background-size: contain;
        }

        &-inner {
            @include position(absolute, 0 0 0 0);
            background: center center no-repeat;
            background-size: cover;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            visibility: hidden;
            opacity: 0;
            z-index: -100;
        }
    }
}




.section--hero {
    padding-top: 275rem/$k;
    padding-bottom: 225rem/$k;

    @media (max-width: $screen_xxlarge) {
        padding-top: 100rem/$k;
        padding-bottom: 100rem/$k;
    }

    @media (max-width: $tablet) {
        padding-top: 75rem/$k;
        padding-bottom: 75rem/$k;
    }

    @media (max-width: $mobile) {
        padding-top: 75rem/$k;
        padding-bottom: 50rem/$k;
    }
}




.summary {
    font-size: 18rem/$k;
    line-height: (24/18);
    letter-spacing: 0.07em;
    @include margin-top(100rem/$k);

    @media (max-width: $tablet) {
        margin-top: 60rem/$k;
        font-size: 16rem/$k;
    }

    @media (max-width: $mobile) {
        margin-top: 30rem/$k;
    }

    &--large {
        font-size: 16rem/$k;
        line-height: (64/32);
        margin-top: -10rem;

        @media (max-width: $tablet) {
            font-size: 16rem/$k;
            margin-top: 0;
        }

        @media (max-width: $mobile) {
            font-size: 16rem/$k;
            margin-top: 0;
        }

        p {
            @include margin-top(20rem/$k);

            @media (max-width: $mobile) {
                margin-top: 20rem/$k;
            }
        }
    }
}


.astronaut {
    @include position(absolute, 0 null null 50%);
    width: 100%;
    margin: -165rem/$k 0 0 65rem/$k;
    max-width: 791rem/$k;
    opacity: 0.45;
    z-index: -1;
    pointer-events: none;

    @media (max-width: $tablet) {
        margin: -120rem/$k 0 0 65rem/$k;
        max-width: 450rem/$k;
    }

    @media (max-width: $mobile) {
        max-width: 300rem/$k;
        margin: -100rem/$k 0 0 -20rem/$k;
    }

    &__inner {
        transform-origin: 50% 50%;
    }

    .b-loaded {
        transition: all 3s;
    }
}



.section--hero-services {

    @media (max-width: $tablet) {
        padding-top: 75rem/$k;
        padding-bottom: 75rem/$k;
    }

    @media (max-width: $mobile) {
        padding-top: 75rem/$k;
        padding-bottom: 0;
    }
}

.title2 {
    position: relative;
    display: inline-block;
    vertical-align: top;
    font-size: 28rem/$k;
    text-transform: uppercase;
    text-align: right;
    overflow: hidden;
    word-spacing: 0.05em;
    padding: 13rem/$k 0 0;

    @media (max-width: $tablet) {
        font-size: 16rem/$k;
        padding-top: 6rem/$k;
    }

    &:before {
        @include position(absolute, 0 0 null 0);
        border-top: 2rem/$k solid currentColor;
        content: '';

        @media (max-width: $tablet) {
            border-top-width: 1px;
        }
    }

    &__main {
        //font-size: 116rem/$k;
        font-size: 4.25rem;
        line-height: 0.7;
        margin: 0 -0.05em;

        @media (max-width: $tablet) {
            font-size: 65rem/$k;
        }

        @media (max-width: $mobile) {
            font-size: 58rem/$k;
        }
    }

    &__sub {
        position: relative;
        display: inline-block;
        vertical-align: top;
        font-size: 28rem/$k;
        line-height: 0.9;
        margin-right: 70rem/$k;

        @media (max-width: $tablet) {
            font-size: 16rem/$k;
            margin-right: 40rem/$k;
        }

        @media (max-width: $mobile) {
            margin-right: 30rem/$k;
        }

        &:before {
            @include position(absolute, 50% 100% 0 null);
            margin-right: 14rem/$k;
            width: 9999px;
            margin-top: -1rem/$k;
            border-top: 2rem/$k solid currentColor;
            content: '';

            @media (max-width: $tablet) {
                border-top-width: 1px;
            }
        }
    }

    &--moon {
        margin-right: 420rem/$k;
        top: 65rem/$k;

        @media (max-width: $tablet) {
            margin-right: 180rem/$k;
        }

        @media (max-width: $mobile) {
            margin-right: 0;
        }
    }
}

.moon-title {
    @include position(absolute, 50% null null 50%);
    transform: translate(-50%,-50%);
    z-index: 1;
}

.moon {
    width: 100%;
    max-width: 550rem/$k;
    margin: 0 auto;
    z-index: -1;
    opacity: 0.7;
    pointer-events: none;

    @media (max-width: $tablet) {
        max-width: 360rem/$k;
    }

    &__inner {
        position: relative;
        transform-origin: 50% 50%;
        padding-bottom: 100%;

        img {
            @include position(absolute, 0 null null 0);
            width: 100%;
        }
    }

    .b-loaded {
        transition: all 3s;
    }
}



.itgbs {
    max-width: 1060rem/$k;
    margin: 0 auto;
    @include margin-top(95rem/$k);

    @media (max-width: $tablet) {
        margin-top: 60rem/$k;
    }

    @media (max-width: $mobile) {
        margin-top: 30rem/$k;
    }

    &__container {
        margin: 0 -50rem/$k;
        @include clearfix;

        @media (max-width: $tablet) {
            margin: 0 -25rem/$k;
        }

        @media (max-width: $mobile) {
            margin: 0;
        }
    }

    &__inner {
        float: left;
        width: 100%;
        font-size: 0;
        margin: -35rem/$k 0;
        display: flex;
        flex-wrap: wrap;

        @media (max-width: $tablet) {
            margin: -25rem/$k 0;
            justify-content: center;
        }

        @media (max-width: $mobile) {
            margin: 0;
        }
    }
}

.itgb {
    display: inline-block;
    vertical-align: top;
    font-size: 1rem;
    width: 50%;
    padding: 35rem/$k 50rem/$k;

    @media (max-width: $tablet) {
        width: 100%;
        padding: 25rem/$k 25rem/$k;
        max-width: 500rem/$k;
    }

    @media (max-width: $mobile) {
        padding: 0;
        margin-top: -1px;
    }

    &__inner {
        display: block;
        display: flex;
        height: 100%;
        text-decoration: none!important;
        color: inherit;

        @media (max-width: $mobile) {
            padding: 30rem/$k 0;
            border-top: 1px solid $color2;
            border-bottom: 1px solid $color2;
        }
    }

    &__icon {
        width: 160rem/$k;
        font-size: 72rem/$k;
        text-align: right;
        flex: auto 0 0;
        padding: 0 50rem/$k 0 0;
        align-self: center;

        @media (max-width: $tablet) {
            width: 130rem/$k;
            padding-right: 50rem/$k;
        }

        @media (max-width: $mobile) {
            font-size: 60rem/$k;
            width: 80rem/$k;
            padding-right: 20rem/$k;
        }
    }

    &__content {
        flex: auto 1 1;
        padding: 20rem/$k 0;
        border-top: 1px solid $color2;
        border-bottom: 1px solid $color2;
        min-height: 100%;

        @media (max-width: $mobile) {
            border: none;
            padding: 0;
        }
    }

    &__title {
        @include margin-top(10rem/$k);
        font-family: $font2;
        font-weight: 400;
        font-size: 24rem/$k;
        line-height: (22/18);
        word-spacing: 0.2em;
        margin-right: -100rem/$k;

        @media (max-width: $mobile) {
            margin-right: 0;
        }
    }

    &__summary {
        @include margin-top(10rem/$k);
        font-weight: 500;
    }
}

.icon--saturn {
    font-size: 73rem/$k;

    @media (max-width: $mobile) {
        font-size: 100%;
    }
}

.icon--drop-drupal {
    width: (65em/72)
}

.icon--drop-sector {
    font-size: 63rem/$k;

    @media (max-width: $mobile) {
        font-size: 55rem/$k;
    }
}

.icon--astronaut-helmet {
}


.v-line-summary {
    margin-left:  50%;
    @include margin-top(40rem/$k);

    @media (max-width: $tablet) {
        margin-top: 30rem/$k;
    }

    @media (max-width: $mobile) {
        margin-left: 0;
        text-align: center;
    }

    &:before {
        display: block;
        margin-bottom: 50rem/$k;
        height: 150rem/$k;
        width: 1px;
        background: $color2;
        content: "";

        @media (max-width: $tablet) {
            height: 100rem/$k;
            margin-bottom: 30rem/$k;
        }

        @media (max-width: $mobile) {
            margin-left: auto;
            margin-right: auto;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}


.image--m-fw {

    @media (max-width: $mobile) {
        margin-left: -20rem/$k;
        margin-right: -20rem/$k;
    }
}


.ltgbs {
    position: relative;
    margin: 0 -15rem/$k;
    @include margin-top(80rem/$k);
    padding: 30rem/$k 0;
    @include clearfix;

    @media (max-width: $tablet) {
        margin-top: 100rem/$k;
        margin-left: -30rem/$k;
        margin-right: -30rem/$k;
    }

    @media (max-width: $mobile) {
        margin-top: 50rem/$k;
        margin-left: -20rem/$k;
        margin-right: -20rem/$k;
        padding: 50rem/$k 0;
    }

    &:before {
        @include position(absolute, 0 -9999rem/$k 0 -9999rem/$k);
        z-index: -1;
        //border-top: 1px solid $color2;
        border-bottom: 1px solid $color2;
        content: '';
    }

    &__inner {
        float: left;
        width: 100%;
        margin: -15rem/$k 0;
        font-size: 0;

        @media (max-width: $tablet) {
            margin: -30rem/$k 0;
            text-align: center;
        }

        @media (max-width: $mobile) {
            margin: -20rem/$k 0;
        }
    }
}

.ltgb {
    display: inline-block;
    vertical-align: top;
    width: 33.33%;
    padding: 15rem/$k;
    font-size: 1rem;
    text-align: center;

    @media (max-width: $tablet) {
        width: auto;
        padding: 30rem/$k;
    }

    @media (max-width: $mobile) {
        display: block;
        padding: 20rem/$k;
    }
}



.cols-wrapper--contact {
    @include margin-top(80rem/$k);
    padding: 0 70rem/$k;

    @media (max-width: $tablet) {
        padding: 0;
        margin-top: 50rem/$k;
    }

    @media (max-width: $mobile) {
        margin-top: 30rem/$k;
    }

    .col--logo {
        padding-top: 95rem/$k;
        text-align: right;

        @media (max-width: $tablet) {
            padding-top: 55rem/$k;
        }

        @media (max-width: $mobile) {
            display: none!important;
        }
    }


    .col--contact {
        //padding-top: 95rem/$k;
        padding-top: 1rem;
        text-align: left;

        @media (max-width: $tablet) {
            padding-top: 55rem/$k;
        }

        @media (max-width: $mobile) {
            //display: none!important;
        }

        .row {
            i {
                margin-right: 10px;
                vertical-align: middle;
                margin-top: -5px;
            }

            padding: 0 0 50px 0;

            @media (max-width: $tablet) {
                padding: 0 0 20px 0;
            }

            @media (max-width: $mobile) {
                padding: 0 0 50px 0;
            }
        }

    }
}


.form--contact {
    max-width: 440rem/$k;
}

.grecaptcha-badge {
    visibility: hidden !important;
}


.logo-w-text {
    display: inline-block;
    vertical-align: top;
    max-width: 215rem/$k;
    text-align: left;
    @include margin-top(50rem/$k);

    &__text {
        @include margin-top(5rem/$k);
        padding-left: 63rem/$k;
    }
}


.sicons {
    display: inline-block;
    margin: 0 -15rem/$k -15rem/$k 0;
    font-size: 0;
}

.sicon {
    display: inline-block;
    vertical-align: top;
    line-height: 1;
    margin: 0 15rem/$k 15rem/$k 0;
    font-size: 30rem/$k;
    color: $color2;

    .icon {
        vertical-align: top;
    }

    &:hover {
        color: $color3;
    }

    &:active {
        color: $color3;
    }
}




.fly-circle {
    position: absolute;
    z-index: -1;
    @include size(800rem/$k);
    background: center center no-repeat;
    background-size: contain;
    transform-origin: 50% 50%;
    will-change: transform, opacity;
    pointer-events: none;

    @media (max-width: $mobile) {
        display: none;
    }

    &--blue {
        background-image: url("data:image/svg+xml,%3Csvg width='802' height='813' viewBox='0 0 802 813' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.3' filter='url(%23filter0_f)'%3E%3Cpath d='M150.714 404.077C146.208 545.314 254.548 660.8 392.698 662.021C530.848 663.242 646.494 549.737 651 408.5C655.506 267.263 547.166 151.778 409.016 150.556C270.866 149.335 155.22 262.84 150.714 404.077Z' fill='%232491EA'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f' x='0.578613' y='0.546387' width='800.557' height='811.484' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='75' result='effect1_foregroundBlur'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");

        &.fly-circle--type-2 {
            //@include size(660rem/$k);
            //background-image: url("data:image/svg+xml,%3Csvg width='668' height='660' viewBox='0 0 668 660' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.3' filter='url(%23filter0_f)'%3E%3Cellipse rx='183.618' ry='179.354' transform='matrix(-0.99996 -0.00896905 -0.0314284 0.999506 334.248 329.913)' fill='%232491EA'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f' x='0.548828' y='0.639404' width='667.398' height='658.547' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='75' result='effect1_foregroundBlur'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
        }

        &.fly-circle--type-3 {
            //@include size(940rem/$k);
            //background-image: url("data:image/svg+xml,%3Csvg width='939' height='924' viewBox='0 0 939 924' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.3' filter='url(%23filter0_f)'%3E%3Cellipse rx='318.657' ry='311.257' transform='matrix(-0.99996 -0.00896905 -0.0314284 0.999506 469.427 461.961)' fill='%232491EA'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f' x='0.629395' y='0.844727' width='937.594' height='922.233' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='75' result='effect1_foregroundBlur'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
        }
    }

    &--red {
        @include size(940rem/$k);
        background-image: url("data:image/svg+xml,%3Csvg width='752' height='731' viewBox='0 0 752 731' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.3' filter='url(%23filter0_f)'%3E%3Cellipse rx='175.578' ry='170.103' transform='matrix(-0.99996 -0.00896905 -0.0314284 0.999506 375.918 360.594)' fill='%23FF4016'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f' x='0.263184' y='-9.43262' width='751.309' height='740.052' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='100' result='effect1_foregroundBlur'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
    }

    &--header {
        left: 50%;
        margin-left: -150rem/$k;
        top: -350rem/$k;
    }

    &--hero-bottom {
        top: 50%;
        margin-top: -400rem/$k;

        &:nth-child(1) {
            left: 0;
            @include size(660rem/$k);
            margin-left: -260rem/$k;
        }

        &:nth-child(2) {
            right: 0;
            @include size(940rem/$k);
            margin-right: -600rem/$k;
        }
    }

    &--clients-bottom {
        top: 50%;
        margin-top: -400rem/$k;

        &:nth-child(1) {
            left: 0;
            margin-top: 50rem/$k;
            @include size(800rem/$k);
            margin-left: -400rem/$k;
        }

        &:nth-child(2) {
            right: 0;
            margin-top: -370rem/$k;
            @include size(740rem/$k);
            margin-right: -150rem/$k;
        }
    }
}


.half-left {
    width: 50%;

    @media (max-width: $mobile) {
        width: 100%;
    }
}

.half-right {
    width: 50%;
    margin-left: auto;

    @media (max-width: $mobile) {
        width: 100%;
    }
}



.section--about {
    padding-top: 85rem/$k;

    @media (max-width: $tablet) {
        padding-top: 75rem/$k;
    }

    .vzblz {
        z-index: 1;
    }


    .nmright {
        margin-right: -200px;

        @media (max-width: $mobile) {
            margin-right: 0px;
        }
    }



    .about-slider-text {
        position: relative;
    }

    .mrgntp {
      margin-top: 0 !important;
    }

}


.cols-wrapper--tgb {
    margin-left: -40rem/$k;
    margin-right: -40rem/$k;

    @media (max-width: $tablet) {
        margin-left: -25rem/$k;
        margin-right: -25rem/$k;
    }

    > .cols {

        @media (max-width: $mobile) {
            display: inline-block;
            vertical-align: top;
            width: 100%;
            margin-bottom: -30px;
        }

        > .col {
            padding: 0 40rem/$k;

            @media (max-width: $tablet) {
                padding-left: 25rem/$k;
                padding-right: 25rem/$k;
            }

            @media (max-width: $mobile) {
                display: block;
                width: auto;
                margin-bottom: 30px;
            }
        }
    }
}


.about-slider {
    //@include margin-top(320rem/$k);
    margin-top: -24rem;
    @include margin-bottom(200rem/$k);

    @media (max-width: $tablet) {
        margin-top: 180rem/$k;
        margin-bottom: 120rem/$k;
    }

    @media (max-width: $mobile) {
        margin-top: 100rem/$k;
        margin-bottom: 100rem/$k;
    }

    .swiper-pagination-container2 {
        max-width: 140rem/$k;
        margin: 0 auto;

        @media (max-width: $tablet) {
            max-width: 140rem/$k;
        }
    }

    .subtitle {
        //font-size: 13rem/$k;
        font-size: 1rem;
        color: #959595;
        @include margin-top(25rem/$k);
        @include margin-bottom(25rem/$k);

        @media (max-width: $mobile) {
            margin-top: 20rem/$k;
            margin-bottom: 20rem/$k;
        }
    }


    p {
        line-height: 1.75rem;
        @media (max-width: $mobile) {

        }
    }

}


.about-block {

}

.about-block__pane {
    display: grid;
    grid-gap: 2rem;
    align-items: center;

    @media (min-width: $mobile) {
        grid-gap: 6rem;
        grid-template-columns: repeat(2, 1fr);
    }
}

.about-block__head {
    margin-bottom: 4rem;
}

.about-block__avatar {
    border: 10px solid white;
    border-radius: 50%;
    width: 250px;
    height: 250px;
    background-size: contain;
    object-fit: contain;
}

.about-block__position {
    font-size: 1.5rem;
    color: #959595;
    text-transform: uppercase;
    text-align: center;

    @media (min-width: $mobile) {
        text-align: left;
    }
}

.about-block__left {
    display: flex;
    justify-content: center;

    @media (min-width: $mobile) {
        justify-content: flex-end;
    }
}

.about-block__right {
    align-self: flex-start;
}

.exps {
    @include margin-top(70rem/$k);
    border-bottom: 1px solid $color2;

    @media (max-width: $mobile) {
        margin-top: 50rem/$k;
    }
}

.exp {
    position: relative;
    border-top: 1px solid $color2;
    padding: 35rem/$k 70rem/$k 35rem/$k 0;
    transition: all 0.4s ease-in-out;

    @media (max-width: $tablet) {
        overflow: hidden;
    }

    @media (max-width: $tablet) {
        padding: 25rem/$k 0;
    }

    &.active {
        padding-top: 50rem/$k;
        padding-bottom: 50rem/$k;

        @media (max-width: $tablet) {
            padding-top: 40rem/$k;
            padding-bottom: 40rem/$k;
        }
    }

    &__content {
        font-size: 16rem/$k;
        font-weight: 500;
        line-height: (21/16);
        color: rgba($color3, 0.75);

        &-inner {

            @media (max-width: $tablet) {
                padding-top: 30rem/$k;
            }
        }
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        @include size(80rem/$k);
        border: 1px solid rgba($color3, 0.35);
        border-radius: 50%;
        font-size: 22rem/$k;
        line-height: 1;

        @media (max-width: $tablet) {
            @include size(50rem/$k);
            font-size: 18rem/$k;
        }

        &:before {
            @include position(absolute, 0 0 0 0);
            content: "";

            @media (max-width: $tablet) {
                @include position(null, -999px -999px -999px -999px);
            }

            .exp.active & {
                display: none;
            }
        }

        &:hover {
            border-color: $color3;
        }

        .icon {
            top: 1rem/$k;
            vertical-align: top;
            transition: all 0.4s ease-in-out;

            .exp.active & {
                transform: rotate(180deg);
            }
        }
    }
}

.cols-wrapper--exp {
    margin-left: -50rem/$k;
    margin-right: -50rem/$k;

    @media (max-width: $tablet) {
        margin-left: 0;
        margin-right: 0;
    }

    > .cols {

        @media (max-width: $tablet) {
            position: relative;
            display: inline-block;
            vertical-align: top;
            width: 100%;
        }

        > .col {
            padding: 0 50rem/$k;

            @media (max-width: $tablet) {
                display: block;
                padding: 0;
                width: auto;
            }

            &:first-child {
                padding-top: 10rem/$k;
                width: 480rem/$k;

                @media (max-width: $tablet) {
                    padding-top: 0;
                    width: auto;
                }
            }

            &--title {
                padding-right: 80rem/$k;
                display: flex;
                flex-direction: column;
                justify-content: center;
                min-height: 50rem/$k;
            }

            &--icon {

                @media (max-width: $tablet) {
                    @include position(absolute, 0 0 null null);
                }
            }
        }
    }
}



.image-blk {
    background-color: black !important;
}


.services-bg-l {
    background-image: url("../static/pic/services-vertical.jpg");
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
    background-repeat: no-repeat ;
}


.services-bg-r {
    background-image: url("../static/pic/services-vertical-f.jpg");
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
    background-repeat: no-repeat ;
}

.testimonials-slider {
    @media (max-width: $tablet) {
        margin-top: 180rem/$k;
        margin-bottom: 120rem/$k;
    }

    @media (max-width: $mobile) {
        margin-top: 100rem/$k;
        margin-bottom: 100rem/$k;
    }

    .swiper-pagination-container2 {
        max-width: 140rem/$k;
        margin: 0 auto;

        @media (max-width: $tablet) {
            max-width: 140rem/$k;
        }
    }
}

.testimonial {
    position: relative;
    padding-left: calc(30px + 1rem);

    @media (min-width: $tablet) {
        padding-left: calc(90px + 4rem);
        font-size: 1.25rem;
    }
}

.testimonial::before {
    content: '';
    display: block;
    background-image: url('data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAABiCAYAAACYsttCAAAFWklEQVR4nO2dzctVVRTGn/WGRV9Yg4rKNEgqEdSMJilEEy2IIKIICqIG5ayRDoJEG4QE0TCaFYTQICtCgjIqqIlg35kYFW8fhqZgFoVRPrHj3Lq93o99ztnnrnXW3r8/YK+1fvu5+5z7DUuQPIPkuaaaMoZbRyTPJ/kayS9IXm2gJXO4dUTySpKf8j+Ok7zVQGtmcOuI5PUkj/B0/iK5xUCL6rh1RHIDyV9GDDbMTpJnG2hXBbeOSN5D8o8pgw3YR/IKA23PFLeOSN5bHV91OExyvYH2Z4JbRyQfajDYgPBoeNjAGJ3i1hHJ+xsOtZBnSJ5pYKTkuHVE8s4WqR7FuyQvNjBaMtw6InlLjZuZOsyTvE59wAS4dRSKRzyNacNv4W5ZbcAEuHVEcinJQx0ONsyO8Dr5zIdsiVtHJM8j+cmMBhuwm+QFMxkwAW4dkRSSL894sAEHSF47oz1sjGtHJB9TGmxAeKPkqs53sQVuHZHcSPKU8nB7wiNshvtZC0uOkkoieRmAjwBc1L3GsfwMYJWIfKvYw1isOZpLtWJ1d/mC8mCBRwxvvl9HJB9VPtICu5MOlRiLjpJcAkiuBrAXgOZrz+FYWykiPyj2MBarjlpfAkguAvC88mCBzYY3368jktsNHGtvG7/rN+uolTSSK6o7Ws1kn6zuaA8q9jAW644aXwKqND1r4Fh7wvDm+3VE8kEDx1p4SfOspIMlpA+OGl0CSC4G8KWB57MbRORN5R5G0hdHTS8B2wwM9pLVza/ohaPaJ0D1LtJnADTfe/8dwAoRmVfsYSx9ctTkBNihPFjgKaubX9EbR7VOAJLrALzXqq32HAawXER+Ve5jJH1zVPcEeLJ5T8nYanXzK3rlKPoECJ9aBfB6q7ba8zmANSLyp3IfI+mjozonwLbmPSVji9XNr/DpqPrMujZ7kg6VmL46ij0BLHwHfbOBHibRS0dTA0DyBgA3N24pDa+KyIfKPYylz45iTgDtZBPAduUeptFbRxOfBVQ/OPCN8osaL4qI2a9+9d3RtBNgk/JgIdmPK9aPodeOxp4A1XfLv1d+Q8P6o7/3jiadAHcoD9aHR3/vHU0KwANtFk7ALhHZr9zDNHrvaOQlgOQSAPMpPjXcgvUi8r5i/Yl4cTSu+fuUB9trefMrXDgaN8BdbRduydPK9WNw4ei0SwDJawAcSLF4Q44BuFxETir2MBFPjkadAHe3XbQlOy1vfoUbR6MCcHuKhVvwnHL9GNw4+t8lgOSlAA6lWrwB+0VkpWL9qXhztPAEuC3Vwg3ZpVw/BleOFgZgY8rFG/CKcv0YXDn69xJAMoThKIALUxaowXcAlokIlepPxaOj4RNgreJggTcsb36FO0fDAbgp5cINeEe5fgzuHA0HYF3qxWvylnL9GNw5Gg7AjakXr8FBEflRsX4s7hz9EwCSywBcknrxGuxTrB2FV0eDE2BtF4vXwOwnfodw6WgQgDVdLF6DD5Trx+DS0SAAq7tYvAYfK9ePwaWjQQA0/4f2hIgcVawfi0tHc9Xv1y7vYvFIvlasHYVnR+EEWApgUVcFIvhKsXYsbh2FACzpavFITP6y9wLcOrIQgJ+U68fg1pGFABxTrh+DW0dzyu9uBY4o14/BrSMLATiuXD8Gt45CABZ3tbgj3DoKAThHuYcTyvVjcOtI86tNA04Z6ME6nTmaS/W/Qc5x62iu+o55YTJuHVm4BBQUKQHInBKAzCkByJwSgMwpAcicEoDMKQHInBKAzCkByJwSgMwpAcicEoDMKQHInBKAzCkByJwSgMwpAcicEoDMKQHInBKAzCkByJwSgMwpAcgZAH8DVwJr/QJDbOMAAAAASUVORK5CYII=');
    background-position: center;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    background-size: 30px;
    position: absolute;
    top: 0;
    left: 0;

    @media (min-width: $tablet) {
        width: 90px;
        height: 90px;
        background-size: 90px;
    }
}

.testimonial__footer {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;

    @media (min-width: $tablet) {
        padding-right: 15rem;
    }
}

.testimonial__cite {
    text-align: right;
    font-style: italic;
    display: flex;
    align-items: center;
}

.testimonial__cite::before {
    content: '';
    display: block;
    border-bottom: 1px solid $color2;
    width: 30px;
    margin-right: 1rem;
}

.section--good-company {
    padding-bottom: 0;
    margin: 0 -15rem/$k;
}

.section--good-company > .container {
    border-bottom: 1px solid $color2;
    padding-bottom: 125rem/$k;
}

.clients {
    display: grid;
    grid-gap: 1rem;
    margin: 3rem 0;
    position: relative;
    
    @media (min-width: $mobile) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.clients::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 1px;
    background: $color2;
    left: 50%;
    display: none;
    
    @media (min-width: $mobile) {
        display: block;
    }
}

.clients__logos {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: $mobile) {
        align-items: flex-end;
    }
}

.clients__logo {
    margin: 1rem;
}

.good-company-description {
    display: grid;
    grid-gap: 1rem;
    margin: 2rem 0;
    position: relative;

    @media (min-width: $mobile) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.good-company-description__content {
    grid-column: 2/span 1;
}

.page-title--case-study {
    margin-top: 0;
    max-width: 860px;
}

.section--case-study {
    padding-top: 0;
}

.tabs__head {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 -2rem;
    margin-bottom: 32rem/$k;
    overflow-x: auto;
}

.tabs__head::before {
    content: '';
    height: 1px;
    background-color: $color2;
    width: calc(100% - 4rem);
    position: absolute;
    left: 2rem;
    bottom: 0;
}

.tabs__tab {
    display: block;
    flex: 0 0 auto;
    margin: 0 1rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-family: $font2;
    color: $color1;
    font-weight: 500;
    font-size: 18rem/$k;
}

.tabs__tab--active {
    color: white;
    font-weight: bold;
}

.tabs__slide {
    position: absolute;
    height: 4px;
    background-color: $color4;
    bottom: 0;
    transition: all 0.2s ease-out;
}

.tabs__tab-item {
    display: none;
}

.tabs__tab-item--active {
    display: block;
}

.case-study-list li {
    position: relative;
    text-indent: 0;
    margin: 16rem/$k 0;
}

.case-study-list li::before {
    position: absolute;
    content: '';
    background: transparent url('../static/img/svg/icon-arrow-right.svg') no-repeat center left;
    height: 24px;
    top: 0;
    left: 0;
}

.case-study-vertical-line {
    height: 100rem/$k;
    width: 2px;
    background-color: $color2;
    margin: 0 auto;

    @media (min-width: $mobile) {
        height: 230rem/$k;
        margin: 0;
    }
}