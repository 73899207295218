
/*
Лейаут шапки
*/

.header {
    @include position(relative, 0 0 null 0);
    z-index: 40;
    line-height: 1.2;
    backface-visibility: hidden;
    will-change: transform;
    transition: all 0.4s;

    &--fixed {
        position: fixed;

        &.header--hidden {
            transform: translateY(-100%);
        }

        &.header--scrolled {
            background-color: rgba(#111, 0.9);
        }
    }

    &-replace {
        height: 210rem/$k;

        @media (max-width: $screen_xxlarge) {
            height: 160rem/$k;
        }

        @media (max-width: $tablet) {
            height: 120rem/$k;
        }

        @media (max-width: $mobile) {
            height: 80rem/$k;
        }

        .header + & {
            display: none;
        }

        .header--fixed + & {
            display: block;
        }

        .header--over + & {
            display: none;
        }
    }

    .col {
        transition: height 0.4s;

        &--cbutton {
            width: 1%;
        }

        &--logo {
            width: 1%;
        }
    }

    &__inner {
        position: relative;
        z-index: 2;
    }

    .col {
        height: 210rem/$k;
        transition: height 0.4s;

        @at-root {

            .header--scrolled#{&} {

                @media (min-width: $tablet+1px) {
                    height: 96rem/$k;
                }
            }
        }

        @media (max-width: $screen_xxlarge) {
            height: 160rem/$k;
        }

        @media (max-width: $tablet) {
            height: 120rem/$k;
        }

        @media (max-width: $mobile) {
            height: 64rem/$k;
        }
    }

    .hmenu {
        top: 7rem/$k;
        transition: top 0.4s;

        @at-root {

            .header--scrolled#{&} {
                top: 0rem/$k;
            }
        }
    }
}

.header--scrolled .icon--logo {
    width: (156em/67);
}

.cols-wrapper--header {

    > .cols {
        table-layout: auto;

        > .col {
            position: relative;
            vertical-align: middle;
            line-height: 1;
            transition: height $ts;

            &--fit {
                width: 1px;
            }

            &--cbutton {
            }
        }
    }
}



/*
Лого
*/

.logo {
    display: inline-block;
    font-size: 67rem/$k;
    line-height: 1;
    text-decoration: none!important;
    transition: all $ts;

    @media (max-width: $tablet) {
        font-size: 50rem/$k;
    }

    .icon {
        vertical-align: top;
    }

    &[href^='#'] {

        .header & {
            pointer-events: none;
        }

        .header--scrolled & {
            pointer-events: auto;
        }
    }
}


.hmenu {
    position: relative;
    @include margin-top(10rem/$k);

    &__list {
        display: inline-block;
        vertical-align: top;
        min-width: 100%;
        font-size: 0;
        margin-left: -75rem/$k;
        margin-bottom: -15rem/$k;

        @media (max-width: $tablet) {
            margin-left: -60rem/$k;
        }
    }

    &__item {
        position: relative;
        display: inline-block;
        vertical-align: top;
        font-size: 18rem/$k;
        font-weight: 500;
        line-height: (22/18);
        margin-left: 75rem/$k;
        margin-bottom: 15rem/$k;

        @media (max-width: $tablet) {
            margin-left: 60rem/$k;
        }

        &.active {

            > .hmenu__link {

                &:after {
                    opacity: 1;
                }
            }
        }
    }

    &__link {
        position: relative;
        padding: 2rem/$k 0;
        color: inherit;
        text-decoration: none!important;
        transition: all $ts;

        &:before {
            @include position(absolute, null 0 0 0);
            border-bottom: 1px solid;
            border-color: currentColor;
            content: '';
            transform-origin: 0 50%;
            transform: scaleX(0);
            transition: all $ts;
        }

        &:after {
            @include position(absolute, -15rem/$k -30rem/$k -15rem/$k -30rem/$k);
            border-radius: 100rem/$k;
            background-color: rgba($color2, 0.15);
            opacity: 0;
            transform: translateY(1rem/$k);
            content: '';
            transition: all $ts;

            @media (max-width: $tablet) {
                @include position(null, -10rem/$k -20rem/$k -10rem/$k -20rem/$k);
            }
        }

        &:hover {

            &:before {
                transform: scaleX(1);
            }
        }
    }
}



/*
Кнопка-гамбургер открытия меню для мобильных
*/

.cbutton {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    @include size(32rem/$k, 20rem/$k);
    text-align: center;
    font-family: Arial, sans-serif;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    z-index: 5;
    transition: all $ts;

    &:hover {
        text-decoration: none;
    }

    &:active {
        text-decoration: none;
    }

    &:before {
        @include position(absolute, -10rem/$k -10rem/$k -10rem/$k -10rem/$k);
        content: '';
        z-index: 1;
    }

    &__inner {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        line-height: 1;
    }

    &__item {
        position: relative;
        display: block;
        @include size(32rem/$k, 2rem/$k);
        background: currentColor;
        @include margin-top(7rem/$k);
        transition: transform 0.5s, opacity $ts;

        .menu-overlay-active & {
            opacity: 0;
            transform: translateY(-9rem/$k) rotate(-45deg);
        }

        &:first-child {

            .menu-overlay-active & {
                opacity: 1;
                transform: translateY(9rem/$k) rotate(135deg);
            }
        }

        &:first-child + * {

            .menu-overlay-active & {
                opacity: 1;
                transform: rotate(45deg);
            }
        }
    }
}



.menu-overlay {
    @include position(absolute, 0 0 null 0);
    background-color: $color0;
    transform: translate3d(0, -100%, 0);
    z-index: -1;
    visibility: hidden;
    transition: transform 0.5s, opacity 0.5s, visibility 0s 0.5s, z-index 0s 0.5s;

    .menu-overlay-active & {
        z-index: 0;
        transform: none;
        visibility: visible;
        transition-delay: 0s;
    }

    &__content {
        max-height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding: 180rem/$k 0 100rem/$k;
        transition: all 0.4s;

        @media (max-height: 850rem/$k) {
            padding: 150rem/$k 0 75rem/$k;
        }
    }

    .hmenu {
        @include margin-top(40rem/$k);
        text-align: center;

        &__list {
            margin-bottom: -40rem/$k;

            @media (max-height: $mobile) {
                margin-bottom: -30rem/$k;
            }
        }

        &__item {
            display: block;
            font-size: 18rem/$k;
            line-height: (32/18);
            margin-bottom: 40rem/$k;

            @media (max-height: $mobile) {
                margin-bottom: 30rem/$k;
            }
        }

        &__link {
            border-bottom: none;
        }
    }
}

.menu-overlay-bg {
    @include position(fixed, 0 0 0 0);
    opacity: 0;
    visibility: hidden;
    z-index: -2;
    background: $color0;
    transition: opacity 0.5s 0.15s, visibility 0s 0.6s, z-index 0s 0.6s;

    .menu-overlay-active & {
        opacity: 1;
        visibility: visible;
        z-index: 35;
        transition-delay: 0s;
    }
}