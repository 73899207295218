
$adaptive_sizes: ('screen-xl': $screen_large, 'tablet': $screen, 'tablet-small': $tablet, 'mobile': $mobile, 'mobile-small': $mobile_small, 'mobile-xsmall': $mobile_xsmall, 'mobile-xxsmall': $mobile_xxsmall, 'mobile-xxxsmall': $mobile_xxxsmall);
$block_types: '', 'inline-block', 'inline', 'table', 'table-row', 'table-cell', 'flex', 'inline-flex';


@each $block_type in $block_types {

    @if ($block_type == '') {
        .only-for-notouch,
        .only-for-touch {
            display: none!important;
        }
    }
    @else {
        .only-for-notouch-#{$block_type},
        .only-for-touch-#{$block_type} {
            display: none!important;
        }
    }
}

@each $block_type in $block_types {

    @if ($block_type == '') {
        .no-touchevents .only-for-notouch,
        .touchevents .only-for-touch {
            display: block!important;
        }
    }
    @else {
        .no-touchevents .only-for-notouch-#{$block_type},
        .touchevents .only-for-touch-#{$block_type} {
            display: #{$block_type}!important;
        }
    }
}

@each $adaptive_size, $adaptive_media in $adaptive_sizes {
    @each $block_type in $block_types {

        @if ($block_type == '') {
            .#{$adaptive_size}-show {
                display: none!important;
            }
        }
        @else {
            .#{$adaptive_size}-show-#{$block_type} {
                display: none!important;
            }
        }
    }
}

@each $block_type in $block_types {

    @if ($block_type == '') {

        .initial-hide {
            display: none!important;
        }

        .initial-show {
            display: block!important;
        }
    }
    @else {
        .initial-show-#{$block_type} {
            display: #{$block_type}!important;
        }
    }
}

@each $adaptive_size, $adaptive_media in $adaptive_sizes {
    @media (max-width: $adaptive_media) {
        @each $block_type in $block_types {

            @if ($block_type == '') {
                .#{$adaptive_size}-hide {
                    display: none!important;
                }
                .#{$adaptive_size}-show {
                    display: block!important;
                }
            }
            @else {
                .#{$adaptive_size}-show-#{$block_type} {
                    display: #{$block_type}!important;
                }
            }
        }
    }
}
