/*

Expansible content

 */

.expand-it-container {
    position: relative;
    overflow: hidden;
    max-height: 0;
    pointer-events: none;
    transition: all 0.4s ease-in-out;

    > .expand-it-inner {
        position: relative;
        top: -99999rem/$k;
        transition-timing-function: ease-in-out;
        transition-delay: 0.4s;
    }
}

.expand-it-container.overflow-visible {
    overflow: visible;
    max-height: 99999px!important;
}

.expand-it-container.active {
    max-height: 99999px;
    pointer-events: auto;

    > .expand-it-inner {
        top: 0;
        transition-delay: 0s;
    }
}