
@mixin placeholder {

    $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";
    @each $placeholder in $placeholders {
        &:#{$placeholder}-placeholder {
            @content;
        }
    }
}

@mixin clearfix() {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin column-break-inside($value: avoid, $value2: avoid-column) {
    -webkit-column-break-inside: $value;
    -moz-column-break-inside: $value;
    -o-column-break-inside: $value;
    -ms-column-break-inside: $value;
    column-break-inside: $value;
    break-inside: $value2;
}

@mixin margin-top($value) {
    margin-top: $value;

    &:first-child {
        margin-top: 0;
    }
}

@mixin margin-bottom($value) {
    margin-bottom: $value;

    &:last-child {
        margin-bottom: 0;
    }
}