@import "bourbon";
@import "mixins";

$color0: #000;
$color1: #888;
$color2: #585154;
$color3: #fff;
$color4: #2491ea;
$color5: #28b7bb;

$ts: 0.3s;
$cwidth: 1260;

$k: 16;
$i: 1350/100;
$it: 768/100;
$im: 360/100;

@include font-face("Lato", '../../static/font/subset-Lato-Regular', ("woff2", "woff")){
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
}

@include font-face("Lato", '../../static/font/subset-Lato-Medium', ("woff2", "woff")){
    font-style: normal;
    font-weight: 500;
    font-display: fallback;
}

@include font-face("Lato", '../../static/font/subset-Lato-Bold', ("woff2", "woff")){
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
}

@include font-face("Goldman", '../../static/font/goldman-v1-latin-regular', ("woff2", "woff")){
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
}

$font1: 'Lato', Arial, sans-serif;
$font2: 'Goldman', Arial, sans-serif;

$screen_xxlarge: 1900px;
$screen_xlarge: 1580px;
$screen_large: 1350px;
$screen: 1259px;
$tablet: 1023px;
$mobile: 767px;
$mobile_small: 479px;
$mobile_xsmall: 413px;
$mobile_xxsmall: 374px;
$mobile_xxxsmall: 359px;
