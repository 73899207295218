/*

Icons

*/


.icon {
    position: relative;
    display: inline-block;
    fill: currentColor;
    width: 1em;
    height: 1em;
    letter-spacing: 0;
    line-height: 1;

    &--default {
        top: -1rem/$k;
        vertical-align: middle;
        font-size: 16rem/$k;
        margin-left: 7rem/$k;
        margin-right: 7rem/$k;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &--16 {
        font-size: 16rem/$k;
    }

    &--20 {
        font-size: 20rem/$k;
    }

    &--24 {
        font-size: 24rem/$k;
    }

    &--32 {
        font-size: 32rem/$k;
    }

    &--48 {
        font-size: 48rem/$k;
    }
}

//svg.icon {
//    pointer-events: none;
//}

.icon--logo {
    width: (196em/67);
    transition: width 0.25s ease-in-out;
}


.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

